<template>
    <!-- <cc-modal :modal="modal" @close="close">

        <div slot="body" class="container-fluid mb-2" id="categories-content">
            <div class="row">
                <div class="col-3 p-2 pl-3" v-for="category in categories" :key="'categoria-' + category.id">
                    <cc-checkbox
                        :label="category.name"
                        :checked="verify_category_provider(category)"
                        @change="(evt) => {
                            if(evt.target.checked){
                                add_category(category)
                            }else{
                                remove_category(category)
                            }
                        }"
                    />
                </div>
            </div>
        </div>

    </cc-modal> -->
    <div class="modal-mask">
        <div class="modal-container">
            <div class="category-modal-header">
                Associar Fornecedor x Categoria
                <span class="material-icons category-modal-close" v-on:click="close()">cancel</span>
            </div>
            <div class="category-modal-body">
                <div class="category-modal-title"># {{provider.name}}</div>
                <div class="category-modal-table">
                    <div class="category-modal-table-cell" v-for="category in categories" :key="'categoria-' + category.id">
                        <span v-if="category.isCheck" v-on:click="toogleCheck(category)" class="material-icons category-modal-check">check_box</span>
                        <span v-else v-on:click="toogleCheck(category)" class="material-icons category-modal-check">check_box_outline_blank</span>
                        {{category.name}}
                    </div>
                </div>
                <div class="category-modal-buttons">
                    <StandardButton class="category-modal-buttons-size" text="cancelar" :action="close"/>
                    <StandardButton class="category-modal-buttons-size" text="salvar" :action="save"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ErrorHandlerService from '@/services/ErrorHandlerService';
import CategoryService from '@/services/v3/providers/category.service'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StandardButton from '@/components/ui/buttons/StandardButton.vue';


export default {
    mixins: [ loaderMixin ],
    props: [ "provider"],
    components: {
        StandardButton
    },
    data() {
        return  {
            svc: new CategoryService(),
            categories: [],
            category_provider: [],
            modal: {
                title: "Associar Fornecedor x Categorias",
                subtitle: `#${this.provider.name}`,
                icon_title: 'fas fa-user-friends',
                permitted: true,
                submit: this.save,
                submit_text: 'Salvar',
                style: {
                    width: "80%"
                }
            }
        }
    },
    methods: {
        toogleCheck(category){
            category.isCheck = !category.isCheck
            this.$forceUpdate()
        },
        load_categories() {
			return this.svc.load_categories().then(response => response.data).then(data => {
				this.categories = data
			})
		},

        load_categories_provider() {
			return this.svc.load_categories_provider(this.provider.id).then(response => response.data).then(data => {
                data.forEach(element => {
                    this.categories.forEach(category => {
                        if (category.id == element.category_id) category.isCheck = true
                    })
                })
				this.category_provider = data
			})
		},
        verify_category_provider(item){
            const element = this.category_provider.filter(x => x.category_id == item.id)
            return (element.length > 0)? true : false;
        },
        add_category(category){
            const element = {provider_id: this.provider.id, category_id: category.id};
            const find = this.category_provider.filter(x => x.category_id == element.category_id && x.provider_id == element.provider_id)
            if(find.length == 0){
                this.category_provider.push(element);
            }
        },
        remove_category(category){
            const element = {provider_id: this.provider.id, category_id: category.id};
            const find = this.category_provider.filter(x => x.category_id == element.category_id && x.provider_id == element.provider_id)
            if(find.length > 0){
                this.category_provider = this.category_provider.filter(x => !(x.category_id == element.category_id && x.provider_id == element.provider_id))
            }

        },
        save(){
            this.present_loader("Salvando categorias...")
            const categoryList = []
            this.categories.forEach(element => {
                if (element.isCheck) categoryList.push({provider_id: this.provider.id, category_id: element.id})
            })
			this.svc.update(this.provider.id, categoryList).then((response) => response.data)
            .then(this.dismiss_loader)
            .catch(error => {
                ErrorHandlerService.handle(error, this.$store);
                if(error.response.data && error.response.data) {
                    this.present_error(error.response.data.mensagem)
                }
            });
        },

        close() {
            this.$emit('close')
        }
    },
    async created() {
        this.present_loader("Carregando Categorias...")
        await this.load_categories()
        await this.load_categories_provider()
        this.dismiss_loader()
        this.$forceUpdate()
    }
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
}
.modal-container {
  width: 90vw;
  background-color: #fff;
  border-radius: 8px;
}
.category-modal-header{
    background-color: #FF7110;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    font-weight: 500;
    font-size: 2em;
    color: #FFFFFF;
    border-radius: 8px 8px 0 0;
}
.category-modal-close{
    font-size: 1.3em;
    cursor: pointer;
}
.category-modal-body{
    padding: 1vh 1vw;
}
.category-modal-title{
    font-weight: 600;
    font-size: 1.5em;
    color: #FF7110;
    margin-top: 2vh;
    margin-left: 1vw;
}
.category-modal-table{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 1vh 2vw;
}
.category-modal-table-cell{
    padding: 1vh 0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1.4em;
    color: #605F5F;
}
.category-modal-check{
    cursor: pointer;
    color: #FF7110;
    margin-right: 1vw;
}
.category-modal-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 2vw;
    margin: 2vh 0;
}
@media only screen and (max-width: 1000px) {
    .modal-mask{font-size: 1.5vw; }
    .modal-container{width: 110vw;}
}
@media only screen and (max-width: 680px){
    .modal-mask{font-size: 2vw; }
    .category-modal-buttons-size{width: 30%;}
    .category-modal-table{grid-template-columns: 1fr 1fr 1fr;}
}
@media only screen and (max-width: 400px){
    .modal-mask{font-size: 3vw; }
    .category-modal-table{grid-template-columns: 1fr 1fr ;}
    .category-modal-buttons-size{width: 45%;}
}
</style>

